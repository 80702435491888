import React from 'react';
import { useTranslation } from 'react-i18next';
import bannerVideo from '../../../images/welcomevideo.mp4';

const WelcomeBanner = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className="relative h-screen flex justify-center items-center overflow-hidden ">
                <video autoPlay loop muted playsInline className="absolute top-0 left-0 w-full h-full object-cover">
                    <source src={bannerVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="text-center z-10 mr-2 ml-2">
                    <h1 className="text-2xl md:text-4xl text-white font-bold mb-4">{t('banner.title')}</h1>
                    <p className="text-white mb-4 max-w-xl mx-auto">{t('banner.description')}</p>
                    <a href="#about">
                        <button className="bg-darkBlue hover:bg-darkBlue-darker text-white font-bold py-2 px-4 rounded">
                            {t('banner.button')}
                        </button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default WelcomeBanner;
