import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SkillsOverview.css';
import javaLogo from '../../../images/logos/language/java-logo-1.png';
import htmlcssLogo from '../../../images/logos/language/html-css-logo.png';
import phpLogo from '../../../images/logos/language/php-logo.png';
import csLogo from '../../../images/logos/language/c#-logo.png';
import springLogo from '../../../images/logos/language/spring-logo.png';
import dotnetLogo from '../../../images/logos/language/dotnet-logo.png';
import reactLogo from '../../../images/logos/language/react-logo.png';
import angularLogo from '../../../images/logos/language/angular-logo.png';
import gitLogo from '../../../images/logos/language/git-logo.png';

const SkillsOverview = () => {
  const { t } = useTranslation();
  const skills = [
    { name: 'HTML & CSS & JS', description: t('skills.description.basic'), imageUrl: htmlcssLogo },
    { name: 'PHP', description: t('skills.description.basic'), imageUrl: phpLogo },
    { name: 'C#', description: t('skills.description.basic'), imageUrl: csLogo },
    { name: 'JAVA', description: t('skills.description.basic'), imageUrl: javaLogo },
    { name: '.NET', description: t('skills.description.basic'), imageUrl: dotnetLogo },
    { name: 'REACT', description: t('skills.description.basic'), imageUrl: reactLogo },
    { name: 'ANGULAR', description: t('skills.description.basic'), imageUrl: angularLogo },
    { name: 'GIT', description: t('skills.description.basic'), imageUrl: gitLogo },
    // { name: 'PYTHON', description: t('skills.description.basic'), imageUrl: gitLogo },
    // { name: 'FAST API', description: t('skills.description.basic'), imageUrl: gitLogo },
    // { name: 'VUE.JS', description: t('skills.description.basic'), imageUrl: gitLogo },
    // A modifier
  ];
  const [visible, setVisible] = useState(false);

  return (
    <section id='section' className="flex justify-center items-center skills-container">
    <h1 className="text-2xl font-bold mb-20 pt-8 text-center">My Skills</h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {skills.map((skill, index) => (
          <div key={index} className="card bg-darkBlue shadow-lg rounded-lg overflow-hidden flex flex-col justify-center items-center p-4 border-4 border-darkBlue max-w-s max-h-80">
            <img 
              className="h-1/2 object-center mb-10 "
              src={skill.imageUrl} 
              alt={skill.name} 
            />
            <div>
              <span className="font-bold">{skill.name}</span>
              <span className="block text-gray-500 text-sm">{skill.description}</span>
            </div>
          </div>
        ))}
      </div>
    </section>

  );
};

export default SkillsOverview;
