import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WelcomeBanner from './WelcomeBanner/WelcomeBanner';
import AboutSnippet from './AboutSnippet/AboutSnippet';
import SkillsOverview from './SkillsOverview/SkillsOverview';
// import other components as needed
import './Home.css';

const Home = ({ lang = 'en' }) => { // Default to 'en' if no lang prop is passed
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    
    <div>
      <section id='home'><WelcomeBanner /></section>
      <section id='about'><AboutSnippet /></section>
      <section id='skills'><SkillsOverview /></section>
      {/* Uncomment or add other sections as needed */}
    </div>
  );
};

export default Home;
