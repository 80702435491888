import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import undepolyedMenuLogo from '../../images/logos/menu.png';
import deployedMenuLogo from '../../images/logos/x.png';

const NavBar = () => {
    const { t } = useTranslation();
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [logoImage, setLogoImage] = useState(undepolyedMenuLogo);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
        setLogoImage(isNavVisible ? undepolyedMenuLogo : deployedMenuLogo);
    };

    return (
        <div className={`fixed z-10 ${isNavVisible ? 'bg-black bg-opacity-80' : 'bg-white'}`}>
        <nav className="p-4">
                <button onClick={toggleNav} className="cursor-pointer w-8 h-8">
                    <img src={logoImage} alt="Logo" className="w-full h-full" />
                </button>

                <ul className={`transform transition-transform duration-450 ease-in-out ${isNavVisible ? 'translate-x-0' : '-translate-x-full'} absolute top-0 left-0 mt-12 bg-white p-4 rounded shadow-md`}>
                    <li className="mb-2"><a href="#home">{t('nav.home')}</a></li>
                    <li className="mb-2"><a href="#about">{t('nav.about')}</a></li>
                    <li className="mb-2"><a href="#skills">{t('nav.skills')}</a></li>
                    {/* <li className="mb-2"><a href="#projects">{t('nav.projects')}</a></li>
                    <li className="mb-2"><a href="#contact">{t('nav.contact')}</a></li>                 */}
                </ul>
            </nav>
        </div>
    );
};

export default NavBar;
