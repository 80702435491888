import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import picture from '../../../images/githubProfilePicture.png';
import './AboutSnippet.css';

const AboutSnippet = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    const section = document.querySelector('.about-snippet');
    const sectionPosition = section.getBoundingClientRect();
    const threshold = 200; // Customize this threshold as needed

    if (sectionPosition.top < window.innerHeight - threshold) {
      setIsVisible(true);
      window.removeEventListener('scroll', checkVisibility);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility);
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  return (
    <section className={`about-snippet flex items-center justify-center${isVisible ? ' animated-fadeIn' : ''} transition-opacity duration-500 ease-in-out`}>

      <div className={`about-content p-8${isVisible ? ' animated-fadeIn' : ''}`}>
        <h1 className="text-2xl font-bold mb-20 pt-8 text-center">{t('about.title')}</h1>

        <div className={`about-image flex justify-center items-center w-full${isVisible ? ' animated-fadeIn' : ''}`}>
          <img src={picture} alt="About Me" className="rounded-full border-4 border-gray-200 shadow-lg" />
        </div>

        <p className="mt-12">{t('about.intro')}</p>
        <p className="mt-8">{t('about.after-intro')}</p>
      </div>
    </section>
  );
};

export default AboutSnippet;
